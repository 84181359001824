import * as React from 'react';
import { View, StyleSheet, Button, Text, Image, ScrollView } from 'react-native';

import useStatusBar from '../hooks/useStatusBar';
import { logout } from '../components/Firebase/firebase';
import logo from '../assets/logo.png'; 

import { AcademicCapIcon, FilmIcon, GlobeAltIcon as GlobeIcon } from "react-native-heroicons/outline";
import { StatusBar } from 'expo-status-bar';
import { LogoutIcon, InformationCircleIcon } from "react-native-heroicons/outline";

import { Video, AVPlaybackStatus } from 'expo-av';
import AppButton from '../components/AppButton';
export default function HomeScreen({navigation}) {
  useStatusBar('dark-content');
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  async function handleSignOut() {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  }
  return ( 
    <ScrollView contentContainerStyle={styles.container}>
        <Image source={logo} style={{ width: 200, height: 69, marginVertical: 35, marginTop: 45 }} /> 

        <AppButton
          withBag={true}
          onPress={() => navigation.navigate('Aprender')}
        >         
           <View style={styles.rowe}>
              <View style={styles.col3}>
                <AcademicCapIcon color="white" width="50" height="50"></AcademicCapIcon>
              </View>
              <View style={styles.col9}>
                <Text style={{...styles.buttonText, color: 'white', textAlign: 'right'}}>Aprender</Text>
              </View>
           </View>
        </AppButton>


        <AppButton
          withBag={true}
          color="secondary"
          onPress={() => navigation.navigate('Peliculas')}
        >         
           <View style={styles.rowe}>
              <View style={styles.col3}>
                <FilmIcon color="black" width="50" height="50"></FilmIcon>
              </View>
              <View style={styles.col9}>
                <Text style={{...styles.buttonText, color: 'black', textAlign: 'right'}}>Películas</Text>
              </View>
           </View>
        </AppButton>

        <AppButton
          withBag={true}
          color="fullBlack"
          onPress={() => navigation.navigate('Noticias')}
        >         
           <View style={styles.rowe}>
              <View style={styles.col3}>
                <GlobeIcon color="white" width="50" height="50"></GlobeIcon>
              </View>
              <View style={styles.col9}>
                <Text style={{...styles.buttonText, color: 'white', textAlign: 'right'}}>Noticias</Text>
              </View>
           </View>
        </AppButton>

        <AppButton
          withBag={true}
          color="ayuda"
          onPress={() => navigation.navigate('Ayuda')}
        >         
           <View style={styles.rowe}>
              <View style={styles.col3}>
                <InformationCircleIcon color="white" width="50" height="50"></InformationCircleIcon>
              </View>
              <View style={styles.col9}>
                <Text style={{...styles.buttonText, color: 'white', textAlign: 'right'}}>Ayuda</Text>
              </View>
           </View>
        </AppButton>

        <AppButton
          withBag={true}
          color="salir"
          onPress={handleSignOut}
        >         
           <View style={styles.rowe}>
              <View style={styles.col3}>
                <LogoutIcon color="white" width="50" height="50"></LogoutIcon>
              </View>
              <View style={styles.col9}>
                <Text style={{...styles.buttonText, color: 'white', textAlign: 'right'}}>Salir</Text>
              </View>
           </View>
        </AppButton>
        <StatusBar style="light" />

    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
    backgroundColor: '#111827'
  },
  buttonText: {
    fontSize: 32,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  video: {
    alignSelf: 'center',
    width: 320,
    height: 200,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowe: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  col3: {
    flex: 1
  },
  col9: {
   flex: 2
  }
});
