import * as React from 'react';
import {ScrollView, View, StyleSheet, Button} from 'react-native';

import useStatusBar from '../hooks/useStatusBar';
import {logout} from '../components/Firebase/firebase';

import {Video, AVPlaybackStatus} from 'expo-av';
import AppButton from '../components/AppButton';

export default function PlayerScreen(props) {
    useStatusBar('dark-content');
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [statusAudio, setStatusAudio] = React.useState({});

    React.useEffect(() => {
        console.log(props)
    }, []);

    async function handleSignOut() {
        try {
            await logout();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <ScrollView style={styles.container}>
            <Video
                ref={video}
                style={styles.video}
                source={{
                    uri: props.route.params.video_url
                }}
                resizeMode="stretch"
                posterSource={{
                  uri: 'https://fondosmil.com/fondo/2277.jpg'
                }}
                useNativeControls={true}
                onPlaybackStatusUpdate={status => setStatus(() => status)}/>

            <View style={{
                    paddingHorizontal: 15
                }}>
                <AppButton
                    color="secondary"
                    title={status.isPlaying
                        ? 'Pausar'
                        : 'Reproducir'}
                    onPress={(
                        ) => status.isPlaying
                        ? video.current.pauseAsync()
                        : video.current.playAsync()}></AppButton>

                <AppButton
                    color="secondary"
                    title="Silenciar"
                    onPress={(
                      ) => status.isMuted
                      ? video.current.setIsMutedAsync(false)
                      : video.current.setIsMutedAsync(true)}></AppButton>
                <AppButton
                    color="secondary"
                    title="Pantalla completa"
                    onPress={() => video.presentFullscreenPlayer()}
                    ></AppButton>

            </View>

        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        minHeight: '100%',
        backgroundColor: 'black'
    },
    video: {
        width: '100%',
        minHeight: 300,
        borderRadius: 6
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});
