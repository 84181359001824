import * as React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {View, Text, Image} from 'react-native';
import HomeScreen from '../screens/HomeScreen';
import LearnScreen from '../screens/LearnScreen';
import AppMenuButton from '../components/AppMenuButton';
import logo from '../assets/logo.png';
import PlayerScreen from '../screens/PlayerScreen';
import MovieScreen from '../screens/MovieScreen';
import LearnPage from '../screens/LearnPage';
import MoviePage from '../screens/MoviePage';
import NewsScreen from '../screens/NewsScreen';
import AyudaScreen from '../screens/AyudaScreen';
import * as ScreenOrientation from 'expo-screen-orientation';

const Stack = createStackNavigator();

function CustomNavigationBar({navigation}) {

    /*
const [delado, setDeLado] = React.useState(false)

    React.useEffect(() => {
      const subscription = ScreenOrientation.addOrientationChangeListener((event) => {
            console.log("orientation changed");
            if (event.orientationInfo.orientation !== 1) {
                setDeLado(true);
            }
        });

        return () => {
          subscription.remove;
        };
    }, [])
*/
    return (
        <View
            style={{
                backgroundColor: 'rgba(31,41,55, 1)',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                paddingBottom: 15
            }}>
            {
                true && <Image
                        source={logo}
                        style={{
                            width: 200,
                            height: 69,
                            marginVertical: 15,
                            marginTop: 35
                        }}/>
            }
            <AppMenuButton
                color="secondary"
                onPress={() => navigation.navigate('Inicio')}
                title="Menu"></AppMenuButton>
        </View>
    );
}

export default function AppStack() {

    return (
        <Stack.Navigator
            screenOptions={{
                header: CustomNavigationBar
            }}>
            <Stack.Screen
                options={{
                    headerShown: false
                }}
                name="Inicio"
                component={HomeScreen}/>
            <Stack.Screen name="Aprender" component={LearnScreen}/>
            <Stack.Screen name="Peliculas" component={MovieScreen}/>
            <Stack.Screen name="Reproductor" component={PlayerScreen}/>
            <Stack.Screen name="LearnPage" component={LearnPage}/>
            <Stack.Screen name="MoviePage" component={MoviePage}/>
            <Stack.Screen name="Noticias" component={NewsScreen}/>
            <Stack.Screen name="Ayuda" component={AyudaScreen}/>
        </Stack.Navigator>
    );
}
