import * as React from 'react';
import {
    View,
    StyleSheet,
    Button,
    Text,
    Image,
    ScrollView
} from 'react-native';

import useStatusBar from '../hooks/useStatusBar';
import {getMovies} from '../components/Firebase/firebase';
import logo from '../assets/logo.png';

import {AcademicCapIcon, FilmIcon, GlobeAltIcon as GlobeIcon} from "react-native-heroicons/solid";
import {StatusBar} from 'expo-status-bar';
import {LogoutIcon, InformationCircleIcon} from "react-native-heroicons/outline";

import {Video, AVPlaybackStatus} from 'expo-av';
import AppButton from '../components/AppButton';
import AppFullMiniButton from '../components/AppFullMiniButton';
export default function MovieScreen({navigation, route}) {
    useStatusBar('dark-content');
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        let getData = async () => {
            let data_import = await getMovies().get()
            let tempBlogs = [];
            data_import
                .docs
                .forEach(item => {
                    tempBlogs.push({data: item.data(), id: item.id});
                })
            setData(tempBlogs)
        }

        getData();
    }, []);

    return (
        <ScrollView contentContainerStyle={styles.container}>
             <Text
                style={{
                    fontSize: 50,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 30
                }}>Películas</Text>
            {
                data.map(function (x) {
                    if(x.data.video_url == undefined) {
                        x.data.video_url = '/'
                    }
                    return (
                        <View key={x.id} style={styles.card}>
                            <Text
                                style={{
                                    fontSize: 20,
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginVertical: 0,
                                    textTransform: 'uppercase'
                                }}>{x.data.category}</Text>
                            <Text
                                style={{
                                    fontSize: 40,
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginVertical: 15
                                }}>{x.data.title}</Text>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignContent: 'flex-start'
                                }}>
                                <View
                                    style={{
                                        flex: 1
                                    }}>
                                    <Image
                                        key={x.id + '_image'}
                                        source={{
                                            uri: x.data.image
                                        }}
                                        resizeMode={'cover'}
                                        style={{
                                            width: '100%',
                                            height: 200,
                                            borderBottomLeftRadius: 24,
                                            borderBottomRightRadius: 24,
                                            borderTopLeftRadius: 24
                                        }}/>
                                </View>
                                <View
                                    style={{
                                        flex: 1
                                    }}>

                                    <View
                                        style={{
                                            borderBottomRightRadius: 24,
                                            borderTopRightRadius: 24
                                        }}>
                                        <AppFullMiniButton
                                            onPress={() => navigation.navigate('Reproductor', {video_url: route.params.video_url})}
                                            title="Empezar"></AppFullMiniButton>
                                        <AppFullMiniButton
                                            onPress={() => navigation.navigate('MoviePage', {
                                                author: x.data.author,
                                                author_img: x.data.author_img,
                                                category: x.data.category,
                                                descripcion: x.data.description,
                                                image_url: x.data.image,
                                                id: x.id,
                                                title: x.data.title,
                                                video_url: x.data.video_url
                                            })}
                                            color="secondary"
                                            title="Leer más"></AppFullMiniButton>
                                    </View>
                                </View>

                            </View>
                        </View>
                    );
                })
            }
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 40,
        paddingHorizontal: 15,
        minHeight: '100%',
        paddingTop: 35,
        backgroundColor: '#111827'
    },
    card: {
        backgroundColor: "rgba(31,41,55, 1)",
        borderRadius: 24,
        marginTop: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.00,
        elevation: 24,
        width: '100%',
        marginBottom: 10,
        padding: 20
    },
    buttonText: {
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    video: {
        alignSelf: 'center',
        width: 320,
        height: 200
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowe: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'flex-start'
    },
    col3: {
        flex: 1
    },
    col9: {
        flex: 2
    }
});
