import * as React from 'react';
import {
    View,
    StyleSheet,
    Button,
    Text,
    Image,
    ScrollView,
    Share
} from 'react-native';

import useStatusBar from '../hooks/useStatusBar';
import {getMovies} from '../components/Firebase/firebase';
import logo from '../assets/logo.png';
import * as Speech from 'expo-speech';
import {AcademicCapIcon, FilmIcon, GlobeAltIcon as GlobeIcon} from "react-native-heroicons/solid";
import {StatusBar} from 'expo-status-bar';
import {LogoutIcon, InformationCircleIcon} from "react-native-heroicons/outline";
import * as Linking from 'expo-linking';
import {Video, AVPlaybackStatus} from 'expo-av';
import AppButton from '../components/AppButton';
import AppMiniButton from '../components/AppMiniButton';
export default function NewsScreen({navigation}) {
    useStatusBar('dark-content');
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(
                    'https://news.abue.org/index.php?v2/top-headlines?country=AR&apiKey=4f698a71657' +
                    'f48979ae0c696665c379e&pageSize=90&category=general'
                );
                const json = await response.json();
                setData(json.articles)
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return function cleanup() {
            Speech.stop();
        }
    }, []);

    const speak = (thing) => {
        Speech.speak(thing);
    };

    const onShare = async (message) => {
        try {
            const result = await Share.share({
                message: message
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>

            <Text
                style={{
                    fontSize: 50,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 30
                }}>Noticias</Text>

            {
                data.map(function (x, i) {
                    return (
                        <View key={i} style={styles.card}>
                            <Image
                                key={i + '_image'}
                                source={{
                                    uri: x.urlToImage
                                }}
                                resizeMode={'cover'}
                                style={{
                                    width: '100%',
                                    height: 200,
                                    borderBottomLeftRadius: 24,
                                    borderBottomRightRadius: 24,
                                    borderTopLeftRadius: 24
                                }}/>
                            <Text
                                style={{
                                    fontSize: 30,
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginVertical: 15
                                }}>{x.title}</Text>

                            <View
                                style={{
                                    borderBottomColor: 'white',
                                    borderBottomWidth: 5
                                }}/>
                            <Text
                                style={{
                                    fontSize: 25,
                                    color: '#eee',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginVertical: 15
                                }}>{x.description}</Text>

                            <AppMiniButton
                                onPress={() => Linking.openURL(x.url)}
                                title="Leer más"></AppMiniButton>
                            <AppMiniButton
                                onPress={() => speak(x.title + '.' + x.description)}
                                color="secondary"
                                title="Escuchar noticia"></AppMiniButton>
                                <AppMiniButton
                                onPress={() => onShare('Compartido a través de Abue: ' + x.title + ' - Leer la noticia completa:' + x.url)}
                                textColor="white"
                                color="salir"
                                title="Compartir Noticia"></AppMiniButton>
                        </View>
                    );
                })
            }
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 40,
        paddingHorizontal: 15,
        minHeight: '100%',
        paddingTop: 35,
        backgroundColor: '#111827'
    },
    card: {
        backgroundColor: "rgba(31,41,55, 1)",
        borderRadius: 24,
        marginTop: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.00,
        elevation: 24,
        width: '100%',
        marginBottom: 10,
        padding: 20
    },
    buttonText: {
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    video: {
        alignSelf: 'center',
        width: 320,
        height: 200
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowe: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'flex-start'
    },
    col3: {
        flex: 1
    },
    col9: {
        flex: 2
    }
});
