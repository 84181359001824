export default {
  primary: 'rgb(245, 0, 87)',
  secondary: 'white',
  black: '#222222',
  white: '#ffffff',
  ghostWhite: '#f8f8ff',
  lightGrey: '#f9f9f9',
  mediumGrey: '#6e6869',
  red: '#fc5c65',
  salir: 'rgb(161, 37, 104)',
  fullBlack: 'black',
  ayuda: 'rgb(34, 87, 122)'
};
