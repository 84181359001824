import * as firebase from 'firebase';
import 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import firebaseConfig from './firebaseConfig';

// Initialize Firebase App

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


export const auth = firebase.auth();
export const firestore = firebase.firestore();


export const getLearn = () => 
  firestore.collection("learn");

export const getMovies = () => 
  firestore.collection("videos");


//////// Validators

export const loginWithEmail = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

export const AnonymousLogin = () =>
  auth.signInAnonymously();

export const registerWithEmail = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

export const logout = () => auth.signOut();

export const passwordReset = email => auth.sendPasswordResetEmail(email);
