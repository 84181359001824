import * as React from 'react';
import {
    View,
    StyleSheet,
    Button,
    Text,
    Image,
    Share,
    ScrollView
} from 'react-native';
import base64 from 'react-native-base64'
import useStatusBar from '../hooks/useStatusBar';
import {getLearn} from '../components/Firebase/firebase';
import logo from '../assets/logo.png';
import * as Speech from 'expo-speech';
import {AcademicCapIcon, FilmIcon, GlobeAltIcon as GlobeIcon} from "react-native-heroicons/solid";
import {StatusBar} from 'expo-status-bar';
import {LogoutIcon, InformationCircleIcon} from "react-native-heroicons/outline";

import {Video, AVPlaybackStatus} from 'expo-av';
import AppFullButton from '../components/AppFullButton';
import AppMiniButton from '../components/AppMiniButton';
export default function LearnPage({navigation, route}) {
    useStatusBar('dark-content');
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [data, setData] = React.useState([]);

    const onShare = async () => {
        try {

            const link = base64.encode(route.params.id);
            const result = await Share.share({
                message: '¡Hola!, te comparto esto que encontré en Abue.org, la plataforma de streaming ' +
                        'para abuelos y abuelas. Enlace: https://abue.org/learn/' + link
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            alert(error.message);
        }
    };

    React.useEffect(() => {

        return function cleanup() {
            Speech.stop();
        }
    }, [])

    const speak = (thing) => {
        Speech.speak(thing);
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>

            <Image
                source={{
                    uri: route.params.image_url
                }}
                resizeMode={'cover'}
                style={{
                    width: '100%',
                    height: 250
                }}/>

            <AppFullButton
                noRadius={true}
                onPress={() => navigation.navigate('Reproductor', {video_url: route.params.video_url})}
                title="Empezar a ver"></AppFullButton>
            <AppFullButton
                color={'white'}
                noRadius={true}
                onPress={() => navigation.goBack()}
                title="Volver al listado"></AppFullButton>
            <Text
                style={{
                    fontSize: 30,
                    color: '#eee',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    marginTop: 30,
                    marginBottom: 15,
                    fontWeight: '500'
                }}>Tutorial</Text>

            <Text
                style={{
                    fontSize: 50,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 15
                }}>{route.params.title}</Text>


            <View style={{
                    marginHorizontal: 10
                }}>
                <View style={styles.card}>
                    <AppMiniButton
                        color="secondary"
                        onPress={() => speak(route.params.descripcion)}
                        title="Escuchar descripción"/>
                    <Text
                        style={{
                            fontSize: 30,
                            color: '#eee',
                            textAlign: 'center',
                            marginVertical: 15,
                            fontWeight: '500'
                        }}>{route.params.descripcion}</Text>
                </View>
            </View>

            <View style={{
                    marginHorizontal: 25,
                    marginVertical: 15
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <View
                        style={{
                            flex: 1
                        }}>
                        <Image
                            source={{
                                uri: route.params.author_img
                            }}
                            style={{
                                width: 100,
                                height: 100,
                                borderRadius: 90
                            }}/>
                    </View>

                    <View
                        style={{
                            flex: 2
                        }}>
                        <Text
                            style={{
                                fontSize: 30,
                                color: 'white',
                                textAlign: 'left',
                                fontWeight: 'bold',
                                marginVertical: 15
                            }}>{route.params.author}</Text>

                    </View>
                </View>
            </View>

            <AppFullButton
                color={'salir'}
                noRadius={true}
                textColor={'white'}
                onPress={onShare}
                title="Compartir"></AppFullButton>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 40,
        minHeight: '100%',
        backgroundColor: '#111827'
    },
    buttonText: {
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    card: {
        backgroundColor: "rgba(31,41,55, 1)",
        borderRadius: 24,
        marginTop: 10,
        width: '100%',
        marginBottom: 10,
        padding: 20
    },
    video: {
        alignSelf: 'center',
        width: 320,
        height: 200
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowe: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'flex-start'
    },
    col3: {
        flex: 1
    },
    col9: {
        flex: 2
    }
});
