import * as React from 'react';
import {
    View,
    StyleSheet,
    Button,
    Text,
    Image,
    ScrollView
} from 'react-native';
import * as Yup from 'yup';
import Form from '../components/Forms/Form';
import FormField from '../components/Forms/FormField';
import FormButton from '../components/Forms/FormButton';
import FormErrorMessage from '../components/Forms/FormErrorMessage';
import useStatusBar from '../hooks/useStatusBar';
import {getMovies} from '../components/Firebase/firebase';
import logo from '../assets/logo.png';

import {AcademicCapIcon, FilmIcon, GlobeAltIcon as GlobeIcon} from "react-native-heroicons/solid";
import {StatusBar} from 'expo-status-bar';
import {LogoutIcon, InformationCircleIcon} from "react-native-heroicons/outline";

import {Video, AVPlaybackStatus} from 'expo-av';
import AppButton from '../components/AppButton';
import AppFullMiniButton from '../components/AppFullMiniButton';

const validationSchema = Yup
    .object()
    .shape({
        consulta: Yup
            .string()
            .required('INGRESE SU CONSULTA')
            .label('Consulta'),
        email: Yup
            .string()
            .required('INGRESE SU EMAIL')
            .email()
            .label('Email')
    });

export default function AyudaScreen({navigation}) {
    useStatusBar('dark-content');
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});
    const [data, setData] = React.useState([]);

    async function handleOn(values) {
        const {consulta, email} = values;

        try {
            await sendReport(consulta, email);
        } catch (error) {}
    }

    const sendReport = async (consulta, email) => {
        console.log(consulta)

        let body = JSON.stringify({
            "access_token": "9578d75c83474baf87ebfbf4237d4ebe",
            "data": {
                "environment": "production",
                "level": "info",
                "endpoint": "api.rollbar.com/api/1/item/",
                "platform": "browser",
                "framework": "browser-js",
                "language": "javascript",
                "server": {},
                "uuid": "3dbf4ed2-fcbb-46b4-85dd-83cd17c17d08",
                "notifier": {
                    "name": "rollbar-browser-js",
                    "version": "2.24.0",
                    "configured_options": {
                        "captureUncaught": true,
                        "captureUnhandledRejections": true,
                        "payload": {
                            "environment": "production"
                        },
                        "rollbarJsUrl": "https://cdn.rollbar.com/rollbarjs/refs/tags/v2.24.0/rollbar.min.js",
                        "async": true
                    },
                    "diagnostic": {
                        "original_arg_types": ["string", "object"]
                    }
                },
                "custom": {
                    "message": "d"
                },
                "request": {
                    "url": "http://localhost:3000/ayuda",
                    "query_string": "",
                    "user_ip": "$remote_ip"
                },
                "client": {
                    "runtime_ms": 14424628,
                    "timestamp": 1634013385,
                    "javascript": {
                        "browser": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Geck" +
                                "o) Chrome/94.0.4606.71 Safari/537.36",
                        "language": "es-419",
                        "cookie_enabled": true,
                        "screen": {
                            "width": 1536,
                            "height": 864
                        },
                        "plugins": [
                            {
                                "name": "PDF Viewer",
                                "description": "Portable Document Format"
                            }, {
                                "name": "Chrome PDF Viewer",
                                "description": "Portable Document Format"
                            }, {
                                "name": "Chromium PDF Viewer",
                                "description": "Portable Document Format"
                            }, {
                                "name": "Microsoft Edge PDF Viewer",
                                "description": "Portable Document Format"
                            }, {
                                "name": "WebKit built-in PDF",
                                "description": "Portable Document Format"
                            }
                        ]
                    }
                },
                "body": {
                    "message": {
                        "body": "Solicitud Prioritaria de Ayuda. DATA => email: null, consulta: d",
                        "extra": {
                            "message": "d"
                        }
                    },
                    "telemetry": [
                        {
                            "level": "info",
                            "type": "navigation",
                            "timestamp_ms": 1633998960075,
                            "body": {
                                "subtype": "DOMContentLoaded"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960398,
                            "body": {
                                "message": "./src/Containers/Dashboard.js\n  Line 11:13:  'user' is assigned a value but n" +
                                        "ever used           no-unused-vars\n  Line 12:13:  'credential' is assigned a " +
                                        "value but never used     no-unused-vars\n  Line 13:13:  'isNewUser' is assigne" +
                                        "d a value but never used      no-unused-vars\n  Line 14:13:  'providerId' is a" +
                                        "ssigned a value but never used     no-unused-vars\n  Line 15:13:  'operationTy" +
                                        "pe' is assigned a value but never used  no-unused-vars"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960399,
                            "body": {
                                "message": "./src/Components/LearnPage.js\n  Line 13:12:   'speechEnabled' is assigned a v" +
                                        "alue but never used                                                           " +
                                        " no-unused-vars\n  Line 63:8:    React Hook useEffect has a missing dependency" +
                                        ": 'fetchData'. Either include it or remove the dependency array  react-hooks/e" +
                                        "xhaustive-deps\n  Line 69:27:   Expected '!==' and instead saw '!='           " +
                                        "                                                                eqeqeq\n  Line" +
                                        " 112:35:  img elements must have an alt prop, either with meaningful text, or " +
                                        "an empty string for decorative images     jsx-a11y/alt-text"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960399,
                            "body": {
                                "message": "./src/Components/MoviePage.js\n  Line 13:12:   'speechEnabled' is assigned a v" +
                                        "alue but never used                                                           " +
                                        " no-unused-vars\n  Line 63:8:    React Hook useEffect has a missing dependency" +
                                        ": 'fetchData'. Either include it or remove the dependency array  react-hooks/e" +
                                        "xhaustive-deps\n  Line 69:27:   Expected '!==' and instead saw '!='           " +
                                        "                                                                eqeqeq\n  Line" +
                                        " 176:35:  img elements must have an alt prop, either with meaningful text, or " +
                                        "an empty string for decorative images     jsx-a11y/alt-text"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960399,
                            "body": {
                                "message": "./src/Containers/Landing.js\n  Line 15:11:  'user' is assigned a value but nev" +
                                        "er used           no-unused-vars\n  Line 16:11:  'credential' is assigned a va" +
                                        "lue but never used     no-unused-vars\n  Line 17:11:  'isNewUser' is assigned " +
                                        "a value but never used      no-unused-vars\n  Line 18:11:  'providerId' is ass" +
                                        "igned a value but never used     no-unused-vars\n  Line 19:11:  'operationType" +
                                        "' is assigned a value but never used  no-unused-vars"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960399,
                            "body": {
                                "message": "./src/Components/LearnCard.js\n  Line 1:17:  'useEffect' is defined but never " +
                                        "used                                                                      no-u" +
                                        "nused-vars\n  Line 1:28:  'useState' is defined but never used                " +
                                        "                                                       no-unused-vars\n  Line " +
                                        "6:45:  img elements must have an alt prop, either with meaningful text, or an " +
                                        "empty string for decorative images  jsx-a11y/alt-text"
                            },
                            "source": "client"
                        }, {
                            "level": "warning",
                            "type": "log",
                            "timestamp_ms": 1633998960399,
                            "body": {
                                "message": "There were more warnings in other files.\nYou can find a complete log in the t" +
                                        "erminal."
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "network",
                            "timestamp_ms": 1633998960774,
                            "body": {
                                "method": "POST",
                                "url": "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=" +
                                        "AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4manz-IBITU",
                                "status_code": 200,
                                "start_time_ms": 1633998960464,
                                "end_time_ms": 1633998960774,
                                "request_content_type": "application/json",
                                "subtype": "xhr",
                                "response_content_type": "application/json; charset=UTF-8"
                            },
                            "source": "client"
                        }, {
                            "level": "error",
                            "type": "log",
                            "timestamp_ms": 1633998960785,
                            "body": {
                                "message": "Warning: Invalid DOM property `%s`. Did you mean `%s`?%s stroke-linecap stroke" +
                                        "Linecap \n    in path (at Home.js:405)\n    in svg (at Home.js:399)\n    in di" +
                                        "v (at Home.js:398)\n    in div (at Home.js:393)\n    in div (at Home.js:382)\n" +
                                        "    in li (at Home.js:381)\n    in ul (at Home.js:101)\n    in div (at Home.js" +
                                        ":93)\n    in div (at Home.js:85)\n    in Home (at App.js:14)\n    in div (at A" +
                                        "pp.js:13)\n    in Router (created by BrowserRouter)\n    in BrowserRouter (at " +
                                        "App.js:11)\n    in App (at src/index.js:7)"
                            },
                            "source": "client"
                        }, {
                            "level": "error",
                            "type": "log",
                            "timestamp_ms": 1633998960785,
                            "body": {
                                "message": "Warning: Invalid DOM property `%s`. Did you mean `%s`?%s stroke-linejoin strok" +
                                        "eLinejoin \n    in path (at Home.js:405)\n    in svg (at Home.js:399)\n    in " +
                                        "div (at Home.js:398)\n    in div (at Home.js:393)\n    in div (at Home.js:382)" +
                                        "\n    in li (at Home.js:381)\n    in ul (at Home.js:101)\n    in div (at Home." +
                                        "js:93)\n    in div (at Home.js:85)\n    in Home (at App.js:14)\n    in div (at" +
                                        " App.js:13)\n    in Router (created by BrowserRouter)\n    in BrowserRouter (a" +
                                        "t App.js:11)\n    in App (at src/index.js:7)"
                            },
                            "source": "client"
                        }, {
                            "level": "error",
                            "type": "log",
                            "timestamp_ms": 1633998960786,
                            "body": {
                                "message": "Warning: Invalid DOM property `%s`. Did you mean `%s`?%s stroke-width strokeWi" +
                                        "dth \n    in path (at Home.js:405)\n    in svg (at Home.js:399)\n    in div (a" +
                                        "t Home.js:398)\n    in div (at Home.js:393)\n    in div (at Home.js:382)\n    " +
                                        "in li (at Home.js:381)\n    in ul (at Home.js:101)\n    in div (at Home.js:93)" +
                                        "\n    in div (at Home.js:85)\n    in Home (at App.js:14)\n    in div (at App.j" +
                                        "s:13)\n    in Router (created by BrowserRouter)\n    in BrowserRouter (at App." +
                                        "js:11)\n    in App (at src/index.js:7)"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "navigation",
                            "timestamp_ms": 1633998962419,
                            "body": {
                                "subtype": "load"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "network",
                            "timestamp_ms": 1633998962507,
                            "body": {
                                "method": "GET",
                                "url": "https://pagead2.googlesyndication.com/getconfig/sodar?sv=200&tid=gda&tv=r20211" +
                                        "007&st=env",
                                "status_code": 200,
                                "start_time_ms": 1633998962419,
                                "end_time_ms": 1633998962507,
                                "subtype": "xhr",
                                "response_content_type": "application/json; charset=UTF-8"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "network",
                            "timestamp_ms": 1634001626152,
                            "body": {
                                "method": "POST",
                                "url": "https://securetoken.googleapis.com/v1/token?key=AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4m" +
                                        "anz-IBITU",
                                "status_code": 200,
                                "start_time_ms": 1634001625742,
                                "end_time_ms": 1634001626152,
                                "request_content_type": "application/x-www-form-urlencoded",
                                "subtype": "xhr",
                                "response_content_type": "application/json; charset=UTF-8"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "network",
                            "timestamp_ms": 1634006422719,
                            "body": {
                                "method": "POST",
                                "url": "https://securetoken.googleapis.com/v1/token?key=AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4m" +
                                        "anz-IBITU",
                                "status_code": 200,
                                "start_time_ms": 1634006422098,
                                "end_time_ms": 1634006422724,
                                "request_content_type": "application/x-www-form-urlencoded",
                                "subtype": "xhr",
                                "response_content_type": "application/json; charset=UTF-8"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "network",
                            "timestamp_ms": 1634012676573,
                            "body": {
                                "method": "POST",
                                "url": "https://securetoken.googleapis.com/v1/token?key=AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4m" +
                                        "anz-IBITU",
                                "status_code": 200,
                                "start_time_ms": 1634012675985,
                                "end_time_ms": 1634012676573,
                                "request_content_type": "application/x-www-form-urlencoded",
                                "subtype": "xhr",
                                "response_content_type": "application/json; charset=UTF-8"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "navigation",
                            "timestamp_ms": 1634013380859,
                            "body": {
                                "from": "/",
                                "to": "/ayuda"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "dom",
                            "timestamp_ms": 1634013384742,
                            "body": {
                                "subtype": "input",
                                "element": "... > form > textarea",
                                "value": "d"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "dom",
                            "timestamp_ms": 1634013384877,
                            "body": {
                                "subtype": "click",
                                "element": "... > button.flex.shadow-2xl.transform.hover:scale-105.text-white.drop-shadow-" +
                                        "2xl.w-full.px-7.py-7.text-3xl.mb-3.cursor-pointer.bg-red-800.text-center.mt-8." +
                                        "font-black[type=\"submit\"]"
                            },
                            "source": "client"
                        }, {
                            "level": "info",
                            "type": "log",
                            "timestamp_ms": 1634013384878,
                            "body": {
                                "message": "Solicitud Prioritaria de Ayuda. DATA => email: null, consulta: d"
                            },
                            "source": "client",
                            "uuid": "3dbf4ed2-fcbb-46b4-85dd-83cd17c17d08"
                        }
                    ]
                },
                "context": ""
            }
        });

        fetch('https://api.rollbar.com/api/1/item/', {
            method: 'POST',
            headers: {
                "accept": "*/*",
                "accept-language": "es-419,es;q=0.9",
                "cache-control": "no-cache",
                "content-type": "application/json",
                "pragma": "no-cache",
                "sec-ch-ua": "\"Chromium\";v=\"94\", \"Google Chrome\";v=\"94\", \";Not A Brand\";v=\"99\"",
                "sec-ch-ua-mobile": "?0",
                "sec-ch-ua-platform": "\"Windows\"",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site",
                "x-rollbar-access-token": "9578d75c83474baf87ebfbf4237d4ebe"
              },
            body: body
        }).then(response => response.json())
        .then(data => console.log(data));


    }
    return (
        <ScrollView contentContainerStyle={styles.container}>
            <Text
                style={{
                    fontSize: 50,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 30
                }}>Ayuda</Text>


<Text
                style={{
                    fontSize: 40,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: '600',
                    marginBottom: 30
                }}>Si tiene alguna duda o algún problema, por favor, contáctenos al siguiente teléfono para ayudarle:</Text>


            <Text
            
                style={{
                    fontSize: 30,
                    color: 'black',
                    borderRadius: 6,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 30,
                    backgroundColor: 'white',
                    padding: 14
                }}>0054-11-2544-6784</Text>

        <AppButton
          title="Ok"
          color="secondary"
          onPress={() => navigation.goBack()}
        />
            {/* <Form
                initialValues={{
                    consulta: '',
                    email: ''
                }}
                validationSchema={validationSchema}
                onSubmit={values => handleOn(values)}>

                <FormField
                    name="consulta"
                    leftIcon="help"
                    placeholder="¿Cuál su consulta?"
                    autoCapitalize="none"
                    as={'textarea'}
                    autoFocus={true}/>

                <FormField
                    name="email"
                    leftIcon="email"
                    placeholder="Correo electronico"
                    autoCapitalize="none"/>

                <FormButton title={'ENVIAR'}/>
            </Form>

            */}
        </ScrollView>
    );
}
const styles = StyleSheet.create({
    container: {
        paddingBottom: 40,
        paddingHorizontal: 15,
        minHeight: '100%',
        paddingTop: 35,
        backgroundColor: '#111827'
    },
    card: {
        backgroundColor: "rgba(31,41,55, 1)",
        borderRadius: 24,
        marginTop: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.00,
        elevation: 24,
        width: '100%',
        marginBottom: 10,
        padding: 20
    },
    buttonText: {
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    video: {
        alignSelf: 'center',
        width: 320,
        height: 200
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowe: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'flex-start'
    },
    col3: {
        flex: 1
    },
    col9: {
        flex: 2
    }
});