import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

import Colors from '../utils/colors';

export default function AppMiniButton({
    title,
    onPress,
    color = 'primary',
    textColor = 'black',
    withBag = false,
    children
}) {
    return (
        <TouchableOpacity
            style={[
                styles.button, {
                    backgroundColor: Colors[color]
                }
            ]}
            onPress={onPress}>
            {
                withBag
                    ? <> {
                        children
                    }
                    </>
                    : <Text
                            style={{
                                ...styles.buttonText,
                                color: (
                                    color == 'primary'
                                        ? Colors.white
                                        : textColor
                                )
                            }}>{title}</Text>
            }
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 8,
        width: '100%',
        marginVertical: 5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.00,
        elevation: 24
    },
    buttonText: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase'
    }
});
