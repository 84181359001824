// Rename this file to "firebaseConfig.js" before use
// Replace all XXXXs with real Firebase API keys

export default {
  apiKey: "AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4manz-IBITU",
  authDomain: "abueorg.firebaseapp.com",
  projectId: "abueorg",
  storageBucket: "abueorg.appspot.com",
  messagingSenderId: "1011171075086",
  appId: "1:1011171075086:web:6413fddbc68671637e485f"
};
